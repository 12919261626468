import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Row,
  Col,
} from 'antd'

import './index.less'

const mapStateToProps = (state) => ({
  seoData: state.providers.seoData,
  layout: state.layout,
})

const Footer = (props) => {
  const {
    seoData,
    layout,
  } = props

  if (!seoData) { return null }

  return (
    <div className="filter-page__footer">
      {seoData.pageText && (
        <div className="filter-page__footer__page-text" dangerouslySetInnerHTML={{ __html: seoData.pageText }} />
      )}

      {seoData.linkSections && (
        <Row gutter={20}>
          {seoData.linkSections.map((section, sectionIndex) => (
            <Col key={sectionIndex} span={layout.portable ? (layout.mobile ? 24 : 12) : 6}>
              <h2 className="filter-page__footer__link-section-title">{section.title}</h2>

              <ul className="filter-page__footer__link-list">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex} className="filter-page__footer__link-list__item">
                    <a href={link.href}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}

Footer.propTypes = {
  seoData: PropTypes.object,
  layout: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(Footer)
