import React from 'react'
import { useSelector } from 'react-redux'

import './index.less'

const SeoHeader = () => {
  const {
    headerHeadline,
    headerSubheadline,
    headerText,
  } = useSelector(state => state.providers.seoData)

  return (
    <header className="filter-page__seo-header">
      {headerHeadline && (
        <h1 className="filter-page__seo-header__title">{headerHeadline}</h1>
      )}

      {headerSubheadline && (
        <h2 className="filter-page__seo-header__sub-title">{headerSubheadline}</h2>
      )}

      {headerText && (
        <div
          className="filter-page__seo-header__text"
          dangerouslySetInnerHTML={{ __html: headerText }}
        />
      )}
    </header>
  )
}

export default SeoHeader
