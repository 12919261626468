import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import useBrowser from 'hooks/browser'

import './index.less'

const Banner = () => {
  const {
    altText,
    href,
    rel,
    variant,
  } = useSelector((state) => ({
    href: state.providers.seoData.bannerTargetUrl,
    rel: state.providers.seoData.bannerLinkRel || undefined,
    altText: state.providers.seoData.bannerAltText,
    variant: state.providers.seoData?.[state.layout.tabletAndUp ? 'desktopBanner' : 'mobileBanner'],
  }), shallowEqual)

  const { canWebp } = useBrowser()

  if (!variant) { return null }

  const Wrapper = href ? 'a' : 'div'
  const wrapperProps = href ? { href, rel, target: '_blank' } : {}
  const standardBanner = variant[canWebp() ? 'webp_url' : 'url']
  const retinaBanner = variant[canWebp() ? 'retina_webp_url' : 'retina_url']

  return (
    <Wrapper className="filter-page__provider-list__results__banner" {...wrapperProps}>
      <img
        className="filter-page__provider-list__results__banner__image"
        src={standardBanner}
        alt={altText}
        srcSet={`
          ${standardBanner} 1x,
          ${retinaBanner} 2x
        `}
      />
    </Wrapper>
  )
}

export default Banner
