import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import Results from './Results'
import NoResults from './NoResults'
import Pagination from './Pagination'
import ResultsCount from './ResultsCount'

import './index.less'

const ProvidersList = (props) => {
  const {
    loading,
    hasResults,
    hasPagination,
  } = useSelector((state) => ({
    loading: state.providers.loading,
    hasResults: state.providers.providerList && state.providers.providerList.length > 0,
    hasPagination: state.providers.totalPages > 0,
  }), shallowEqual)

  return (
    <div className="filter-page__provider-list">
      {loading.isActive && loading.isInitial && (
        <div className="filter-page__provider-list__loading" />
      )}

      {hasResults ? (
        <>
          <ResultsCount />

          <Results {...props} />

          {hasPagination > 0 && (
            <Pagination />
          )}
        </>
      ) : (
        <NoResults />
      )}
    </div>
  )
}

export default ProvidersList
