import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sticky from 'react-sticky-el'
import dynamic from 'next/dynamic'
import classNames from 'classnames'

import StaticDesktopMap from './Static'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { setDesktopMapMaximized } from 'store/modules/ProvidersFilter'
import { pushDataLayer } from 'helpers/googleAnalytics'
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'

import './index.less'
import '../shared.less'

const MapSearch = dynamic(() => import('apps/FilterPage/components/MapSearch'), {
  ssr: false,
  loading: () => <StaticDesktopMap />,
})

const DesktopMap = () => {
  const dispatch = useDispatch()
  const [height, setHeight] = useState()
  const [headerHeight, setHeaderHeight] = useState()
  const isDesktopMapMaximized = useSelector(state => state.providersFilter.isDesktopMapMaximized)

  useDidUpdateEffect(() => {
    pushDataLayer({
      event: 'googleAnalyticsGenericEvent',
      eventCategory: 'Map',
      eventAction: 'Fullscreen',
      eventLabel: isDesktopMapMaximized ? 'enabled' : 'disabled',
    })
  }, [isDesktopMapMaximized])

  useEffect(() => {
    let timeoutId
    const calcHeights = () => {
      const headerHeight = document.querySelector('.filter-page__header').getBoundingClientRect().height

      setHeight(window.innerHeight - headerHeight)
      setHeaderHeight(headerHeight)
    }
    const resizeHandler = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(calcHeights, 10)
    }

    calcHeights()
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const styles = {}

  if (height) {
    styles.height = `${height}px`
  }

  return (
    <Sticky
      boundaryElement=".filter-page__content__map"
      hideOnBoundaryHit={false}
      topOffset={-headerHeight}
      stickyStyle={{ paddingTop: `${headerHeight}px` }}
    >
      <div className="filter-page__map__desktop" style={styles}>
        {height && (
          <MapSearch>
            <Button
              type="default"
              onClick={() => dispatch(setDesktopMapMaximized(!isDesktopMapMaximized))}
              className={classNames(
                'ant-btn--brand-font',
                'filter-page__map__button',
                'filter-page__map__desktop__resize',
              )}
            >
              <Icon name={isDesktopMapMaximized ? 'compress' : 'expand'} />
            </Button>
          </MapSearch>
        )}

        {!height && (
          <StaticDesktopMap />
        )}
      </div>
    </Sticky>
  )
}

export default DesktopMap
