import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import mapBoxStyles from 'config/mapboxStyles'

import './index.less'

const StaticDesktopMap = () => {
  const intl = useIntl()
  const bounds = useSelector(state => (
    state.providersFilter.filters.bounds.north ? state.providersFilter.filters.bounds : state.providersFilter.bounds
  ))
  const mapBoxApiKey = useSelector(state => state.headerData.mapboxAccessToken)
  const mapStyle = mapBoxStyles(intl.locale).replace('mapbox://styles/', '')

  return (
    <div className="filter-page__map__desktop__static">
      <img
        className="filter-page__map__desktop__static__image"
        src={[
          `https://api.mapbox.com/styles/v1/${mapStyle}/static/`,
          `[${bounds.west},${bounds.south},${bounds.east},${bounds.north}]`,
          '/900x900',
          `?access_token=${mapBoxApiKey}`,
        ].join('')}
      />
    </div>
  )
}

export default StaticDesktopMap
