import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import QueryString from 'qs'

import Card from 'components/Card'
import FormattedMessage from 'components/FormattedMessage'
import FormattedPrice from 'components/FormattedPrice'
import Icon from 'components/Icon'
import StatusBadge from 'components/ProviderCard/StatusBadge'

import { canWebp } from 'helpers/browser'
import classNames from 'helpers/css'
import { buildUrl } from 'helpers/params'
import { getDefaultProviderPicture, getMaximumRoomCapacity, getSuitableRoom } from 'helpers/providerInfos'

import { RootState } from 'store/reducer'
import { Provider } from 'store/types/ProviderProfilePage'

import styles from './index.module.scss'

const ProviderCard: React.FC<ProviderCardProps> = ({ provider, eventCategoryId, queryParams }) => {
  const { filters, currentUser } = useSelector((state: RootState) => ({
    filters: state.providersFilter.filters,
    currentUser: state.currentUser,
  }), shallowEqual)
  const { rooms = [], priceIndications = [], path, title, location, requestStatus } = provider
  const consultantHighlight = `small-provider-card--highlight-${
    provider.consultantInformation?.isCommissionPerRequest
      ? 'commission-per-request'
      : provider.consultantInformation?.productVisibility
  }`

  const providerPicture = getDefaultProviderPicture(provider, eventCategoryId, canWebp())
  const selectedCategoryId = eventCategoryId || filters.occasionFilter
  const providerPath = buildUrl(path, queryParams)

  const priceEstimation = selectedCategoryId
    ? priceIndications.find((indication) => indication.eventCategory.id === selectedCategoryId)
    : [...priceIndications].sort((a, b) => parseFloat(a.min) - parseFloat(b.min))[0]

  const guests = filters.guestCount
    ? getSuitableRoom(filters, rooms)?.maxGuests
    : getMaximumRoomCapacity(rooms) && getMaximumRoomCapacity(rooms)

  return (
    <a
      href={providerPath}
      target="_blank"
      className={
        currentUser.isConsultant
          ? classNames(styles, consultantHighlight)
          : ''
      }
    >
      <Card
        className={classNames(styles, 'small-provider-card')}
        image={providerPicture}
        headAddOn={requestStatus && <StatusBadge requestStatus={requestStatus} />}
        title={title}
        subtitle={location}
      >
        <div className={classNames(styles, 'small-provider-card__content')}>
          {guests && (
            <div className={classNames(styles, 'small-provider-card__content__attribute')}>
              <Icon name="people" />
              {' '}
              {guests}
              {' '}
              <FormattedMessage id="common.guests" />
            </div>
          )}
          {priceEstimation && (
            <div className={classNames(styles, 'small-provider-card__content__attribute')}>
              <Icon name="money-static" />
              {' '}
              {parseInt(priceEstimation.min)}
              {'–'}
              <FormattedPrice
                price={priceEstimation.max}
                asInteger
              />
              {' '}
              <FormattedMessage id="common.perPerson" />
            </div>
          )}
        </div>
      </Card>
    </a>
  )
}

interface ProviderCardProps {
  provider: Provider
  eventCategoryId: number | string
  queryParams?: QueryString.ParsedQs
}

export default ProviderCard
