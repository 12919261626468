import { useEffect, useRef } from 'react'

/* How to use:
 * import useDidUpdateEffect from 'hooks/useDidUpdateEffect'
 * Use like useEffect
*/
const useDidUpdateEffect = (callback, dependencies) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      return callback()
    } else {
      didMountRef.current = true
    }
  }, dependencies)
}

export default useDidUpdateEffect
