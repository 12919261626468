import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import './index.less'

const LoadingBar = () => {
  const isActive = useSelector((state) => (state.providers.loading.isActive))
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    if (!isActive) {
      setIsSuccess(true)

      setTimeout(() => {
        setIsSuccess(false)
      }, 600)
    }
  }, [isActive, setIsSuccess])

  return (
    <div className="filter-page__loading-bar">
      <div className={classNames(
        'filter-page__loading-bar__progress',
        {
          'filter-page__loading-bar__progress--animate': isActive,
          'filter-page__loading-bar__progress--success': isSuccess,
        },
      )} />
    </div>
  )
}

export default LoadingBar
