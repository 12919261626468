import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import dynamic from 'next/dynamic'

import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'

import { toggleMobileMap as toggleMobileMapAction } from 'store/modules/ProvidersFilter'

import { disableScroll, enableScroll } from 'helpers/scroll'

import ToggleFiltersButton from 'apps/FilterPage/ProviderFilter/MobileFilters/components/ToggleFiltersButton'

import './index.less'

const MapSearch = dynamic(() => import('apps/FilterPage/components/MapSearch'), {
  ssr: false,
  loading: () => <Spinner fixedFullHeight />,
})

const MobileMap = () => {
  const dispatch = useDispatch()
  const toggleMobileMap = bindActionCreators(toggleMobileMapAction, dispatch)
  const isMobileFilterExpanded = useSelector(state => state.providersFilter.isMobileFilterExpanded)

  useEffect(() => {
    disableScroll()

    return () => enableScroll()
  }, [])

  return (
    <div
      className={classNames(
        'filter-page__map__mobile',
        { 'filter-page__map__mobile--open-filters': isMobileFilterExpanded },
      )}
    >
      <MapSearch
        searchHereDisabled
      >
        <Button
          type="default"
          onClick={() => toggleMobileMap()}
          className={classNames(
            'ant-btn--brand-font',
            'provider-filter__button',
            'filter-page__map__button',
            'filter-page__map__mobile__back',
          )}
        >
          <Icon name="angle-left" />
          <FormattedMessage id="portableFooterButtons.list" />
        </Button>

        <ToggleFiltersButton className="filter-page__map__mobile__switch-to-filters filter-page__map__button" />
      </MapSearch>
    </div>
  )
}

export default MobileMap
