import dynamic from 'next/dynamic'

// import critical styles for this component because critical styles can't be loaded dynamically
import 'components/Button/Group/index.less'
import '../shared.less'
import './index.less'
import './MoreFilters/index.less'
import './components/FilterButtonWithOverlay/index.critical.less'

const DesktopFiltersLoader = dynamic(
  () => import('./index'),
  { ssr: true },
)

export default DesktopFiltersLoader
