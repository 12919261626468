export const STORAGE_KEY = 'ContactRequestFormData'
export const KEEP_STORED_FORM_KEYS = ['projectId', 'message', 'eventCategory', 'roomType', 'numberOfPersons']

export const DEFAULT_START_TIME = '08:00'
export const DEFAULT_END_TIME = '18:00'

const PAYMENT_PROCESSING_DAYS = 7

export const PAYMENT_PENDING_PERIOD = 90 - PAYMENT_PROCESSING_DAYS

export const VAT_RATE = 0.19
