import React from 'react'
import { useIntl } from 'react-intl'

import FormattedMessage from 'components/FormattedMessage'
import Button from 'components/Button'

import classNames from 'helpers/css'
import { getEventincBusinessLink } from 'helpers/localization'
import { buildUrl } from 'helpers/params'

import styles from './index.module.scss'

import backgroundImage from '@assets/images/secret-portfolios-fencing-background.jpg'

const FencingCard: React.FC<FencingCardProps> = ({
  className,
  feature = 'secret-portfolio',
}) => {
  const intl = useIntl()

  return (
    <div
      className={classNames(styles, 'fencing-card', className)}
      style={{ backgroundImage: `url('${backgroundImage.src}')` }}
    >
      <div className={classNames(styles, 'fencing-card__content')}>
        <div className={classNames(styles, 'fencing-card__content__head')}>
          <FormattedMessage id="subscription.fencingcard.title" />
        </div>

        <div className={classNames(styles, 'fencing-card__content__body')}>
          <FormattedMessage id="subscription.fencingcard.body" />
        </div>

        <div className={classNames(styles, 'fencing-card__content__footer')}>
          <Button
            size="large"
            type="business"
            href={buildUrl(getEventincBusinessLink(intl.locale), { feature })}
            target="_blank"
            translationKey="subscription.badge.upgrade"
            className={classNames(
              styles,
              'fencing-card__content__footer__button',
              'ant-btn--with-border-radius-large',
              'ant-btn--brand-font',
            )}
          />
        </div>
      </div>
    </div>
  )
}

interface FencingCardProps {
  className?: string
  feature?: string
}

export default FencingCard
