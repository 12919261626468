import React from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useRouter } from 'next/router'

import FormattedMessage from 'components/FormattedMessage'
import Button from 'components/Button'

import { clearProvidersFilter as clearProvidersFilterActionCreator } from 'store/modules/ProvidersFilter'
import { clearPreselectedFormData } from 'hooks/preselectedFormData'
import { applyFilters } from 'helpers/filters'

import './index.less'

const NoResults = () => {
  const router = useRouter()
  const {
    hasActiveFilters,
    allEventLocationsUrl,
  } = useSelector((state) => ({
    hasActiveFilters: state.providersFilter.activeFiltersCount > 0,
    allEventLocationsUrl: state.providersFilter.allEventLocationsUrl,
  }), shallowEqual)
  const dispatch = useDispatch()
  const clearProvidersFilter = bindActionCreators(clearProvidersFilterActionCreator, dispatch)

  const buttonProps = hasActiveFilters ? {
    translationKey: 'common.clearAll',
    onClick: () => {
      clearProvidersFilter(() => {
        applyFilters(router)
      })
      clearPreselectedFormData()
    },
  } : {
    translationKey: 'searchPage.searchPortfolio',
    href: allEventLocationsUrl,
  }

  return (
    <div className="filter-page__provider-list__no-results">
      <h4 className="filter-page__provider-list__no-results__text">
        <FormattedMessage id="providersFilter.noResults" />
      </h4>

      <Button
        className="ant-btn--with-border-radius-large ant-btn--brand-font"
        type="primary"
        size="large"
        {...buttonProps}
      />
    </div>
  )
}

export default NoResults
