import { useEffect } from 'react'

export const useMediaQuery = (mediaQuerySet, dependencies) => {
  useEffect(() => {
    if (!mediaQuerySet) { return }

    const mediaQueries = Object.keys(mediaQuerySet)
    const matchMediaInstances = mediaQueries.map((mediaQuery) => {
      const matchMedia = window.matchMedia(mediaQuery)
      const callback = (media) => {
        if (media.matches) {
          mediaQuerySet[mediaQuery]()
        }
      }

      if (matchMedia.addEventListener) {
        matchMedia.addEventListener('change', callback)
      } else {
        matchMedia.addListener(callback)
      }

      callback(matchMedia)

      return {
        matchMedia,
        callback,
      }
    })

    return () => {
      matchMediaInstances.forEach(({ matchMedia, callback }) => {
        if (matchMedia.removeEventListener) {
          matchMedia.removeEventListener('change', callback)
        } else {
          matchMedia.removeListener(callback)
        }
      })
    }
  }, [mediaQuerySet, ...dependencies])
}
