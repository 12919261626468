import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

import { toggleMobileFilters as toggleMobileFiltersAction } from 'store/modules/ProvidersFilter'

import Badge from 'components/Badge'
import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import '../../../shared.less'

const ToggleFiltersButton = (props) => {
  const { className } = props
  const dispatch = useDispatch()
  const toggleMobileFilters = bindActionCreators(toggleMobileFiltersAction, dispatch)
  const activeFiltersCount = useSelector((state) => state.providersFilter.activeFiltersCountMobile)

  return (
    <Button
      type="default"
      onClick={toggleMobileFilters}
      className={classNames(
        'ant-btn--brand-font',
        'provider-filter__button',
        { 'provider-filter__button--selected': activeFiltersCount > 0 },
        className,
      )}
    >
      <Icon name="filter" />
      <FormattedMessage id="common.filters" />

      {activeFiltersCount > 0 && (
        <Badge>{activeFiltersCount}</Badge>
      )}
    </Button>
  )
}

ToggleFiltersButton.propTypes = {
  className: PropTypes.string,
}

export default ToggleFiltersButton
