import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Pagination as AntPagination } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { addQueryStringParamsToUrl } from 'helpers/params'

import './index.less'

const Pagination = () => {
  const router = useRouter()
  const {
    page,
    perPage,
    totalWithoutPremiumProviders,
  } = useSelector((state) => ({
    page: state.providers.page,
    totalWithoutPremiumProviders: state.providers.totalWithoutPremiumProviders,
    perPage: state.providers.perPage,
  }), shallowEqual)

  const renderer = (targetPage, type) => {
    let [currentPath, currentSearchParams] = router.asPath.split('?')

    if (currentSearchParams) {
      currentSearchParams = '?' + currentSearchParams
    }

    if (page === targetPage && type !== 'next') {
      return targetPage
    }

    let targetPath = `${currentPath}${currentSearchParams}`

    if (targetPage && targetPage > 0) {
      let goToPage = targetPage

      if (goToPage === 1) { goToPage = undefined }

      targetPath = addQueryStringParamsToUrl({ pathname: currentPath, search: currentSearchParams }, { page: goToPage })
    }

    switch (type) {
      case 'prev':
        if (!currentSearchParams) {
          return <>&lt;</>
        }

        return <Link href={targetPath}><a rel={type}>&lt;</a></Link>
      case 'next':
        return <Link href={targetPath}><a rel={type}>&gt;</a></Link>
      case 'jump-prev':
      case 'jump-next':
        return <Link href={targetPath}><a>&#8230;</a></Link>
      default:
        return <Link href={targetPath}><a>{targetPage}</a></Link>
    }
  }

  return (
    <AntPagination
      className="filter-page__provider-list__pagination"
      current={page}
      total={totalWithoutPremiumProviders}
      pageSize={perPage}
      itemRender={renderer}
      onChange={() => {}}
    />
  )
}

export default Pagination
