import React from 'react'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const Card: React.FC<CardProps> = ({
  className,
  image,
  children,
  title,
  subtitle,
  clickable,
  selected,
  headAddOn,
  ...restProps
}) => (
  <div
    className={classNames(styles, className, 'ui-components__card', {
      'ui-components__card--clickable': clickable,
      'ui-components__card--selected': selected,
    })}
    {...restProps}
  >
    {image && (
      <div className={classNames(styles, 'ui-components__card__head')}>
        <img className={classNames(styles, 'ui-components__card__head__image')} src={image} />
        {headAddOn}
      </div>
    )}

    <div className={classNames(styles, 'ui-components__card__body')}>
      {title && (
        <div className={classNames(styles, 'ui-components__card__body__title')}>{title}</div>
      )}
      {subtitle && (
        <div className={classNames(styles, 'ui-components__card__body__subtitle')}>{subtitle}</div>
      )}
      {children}
    </div>
  </div>
)

export interface CardProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode,
  subtitle?: React.ReactNode,
  image?: string
  children: React.ReactNode
  clickable?: boolean
  selected?: boolean
  headAddOn?: React.ReactNode
}

export default Card
