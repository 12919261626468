import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Sticky from 'react-sticky-el'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Header from 'components/Header'
import PageHead from 'containers/PageHead'
import { setPreselectedFormData } from 'hooks/preselectedFormData'
import { STORAGE_KEY } from 'components/ContactRequestFormComponents/constants'

import storage from 'helpers/storage'
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'
import { addQueryStringParamsToUrl } from 'helpers/params'

import Footer from 'apps/FilterPage/Footer'
import ProviderFilter from 'apps/FilterPage/ProviderFilter'

import { setProvidersFilter } from 'store/modules/ProvidersFilter'

import ServiceToast from './ServiceToast'
import SeoHeader from './SeoHeader'
import ProviderList from './ProviderList'
import DesktopMap from './Map/Desktop'
import MobileMap from './Map/Mobile'
import LocationTypeCards from './LocationTypeCards'
import LoadingBar from './LoadingBar'

import './index.less'

const FilterPage = (props) => {
  const {
    desktop,
    page,
    totalPages,
    headerData,
    filters,
    isDesktopMapExpanded,
    isDesktopMapMaximized,
    hasLocationTypeCards,
    providerType,
    isMobileMapExpanded,
    hasSeoData,
  } = useSelector((state) => ({
    desktop: state.layout.desktop,
    page: state.providers.page,
    totalPages: state.providers.totalPages,
    headerData: state.providers.headerData,
    filters: state.providersFilter.filters,
    isDesktopMapExpanded: state.providersFilter.isDesktopMapExpanded,
    isDesktopMapMaximized: state.providersFilter.isDesktopMapMaximized,
    hasLocationTypeCards: !!state.providersFilter.locationTypeCards?.length,
    providerType: state.providers.providerType,
    isMobileMapExpanded: state.providersFilter.isMobileMapExpanded,
    hasSeoData: !!state.providers.seoData,
  }), shallowEqual)

  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    const { guestCount, seatingPlans, occasionFilter } = filters
    const storageValues = storage.get(STORAGE_KEY) || {}

    setPreselectedFormData({
      numberOfPersons: guestCount || storageValues.numberOfPersons,
      roomType: seatingPlans || storageValues.roomType,
      eventCategory: occasionFilter,
    })
  }, [])

  const queryProjectId = router?.query.projectId
  const projectId = Number(filters.projectId) || queryProjectId

  const isProviderTypeSupportsMap = ['event_location', 'event_room'].includes(providerType)
  const showDesktopMap = isDesktopMapExpanded && desktop && isProviderTypeSupportsMap
  const showMobileMap = isMobileMapExpanded && !desktop && isProviderTypeSupportsMap

  useDidUpdateEffect(() => {
    if (showDesktopMap) {
      window.scrollTo(0, 0)
    }
  }, [showDesktopMap])

  const onProjectSelect = useCallback((newProjectId) => {
    if ((!newProjectId && !projectId) || (Number(newProjectId) === Number(projectId))) {
      return false
    }

    dispatch(setProvidersFilter({ projectId: newProjectId },
      () => {
        const pathname = addQueryStringParamsToUrl(window.location, { projectId: newProjectId })

        router.push(pathname)
      },
    ))
  }, [projectId])

  return (
    <>
      {headerData && (
        <PageHead
          headerData={headerData}
          page={page}
          totalPages={totalPages}
        />
      )}

      <div
        className={classNames('filter-page', {
          'filter-page--disabled-map': !showDesktopMap,
        })}
      >
        <Sticky
          className="filter-page__header"
          boundaryElement=".filter-page"
          hideOnBoundaryHit={false}
        >
          <Header onProjectSelect={onProjectSelect} />

          {!props.hideFilters && (
            <ProviderFilter />
          )}

          <LoadingBar />
        </Sticky>

        <div className="filter-page__content">
          {(!isDesktopMapMaximized || !showDesktopMap) && (
            <div className="filter-page__content__list">
              {hasSeoData && (
                <SeoHeader />
              )}

              {hasLocationTypeCards && isProviderTypeSupportsMap && (
                <LocationTypeCards />
              )}

              <ProviderList
                isMapVisible={showDesktopMap}
                projectId={projectId}
              />
            </div>
          )}

          {showDesktopMap && (
            <div className="filter-page__content__map">
              <DesktopMap />
            </div>
          )}
        </div>

        {showMobileMap && (
          <MobileMap />
        )}
      </div>

      <Footer />

      <ServiceToast />
    </>
  )
}

FilterPage.propTypes = {
  hideFilters: PropTypes.bool,
}

export default FilterPage
