import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import Spinner from 'components/Spinner'
import { filterSections } from 'store/modules/ProvidersFilter'

import DesktopFilters from './DesktopFilters/loader'
import MobileFilters from './MobileFilters'

const MobileFiltersOverlay = dynamic(() => import('./MobileFilters/Overlay'), {
  ssr: false,
  loading: () => <Spinner fixedFullHeight />,
})

const ProviderFilter = (props) => {
  const { forceMobileFilters } = props
  const {
    portable,
    isMobileFilterExpanded,
    isHotel,
    section,
    isConsultant,
    countryName,
  } = useSelector((state) => ({
    portable: state.layout.portable,
    isMobileFilterExpanded: state.providersFilter.isMobileFilterExpanded,
    isHotel: state.providersFilter.isHotel,
    section: state.providersFilter.filters.section,
    isConsultant: state.currentUser?.isConsultant,
    countryName: state.headerData?.countryName,
  }), shallowEqual)

  const [mountMobileFilters, setMountMobileFilters] = useState(false)

  useEffect(() => {
    if (mountMobileFilters !== isMobileFilterExpanded) {
      if (isMobileFilterExpanded) {
        setMountMobileFilters(true)
      } else {
        window.setTimeout(() => { setMountMobileFilters(false) }, 200)
      }
    }
  })

  const filterSection = filterSections[section || 'events']
  const { onEnterSectionFilters, visibleFilters } = filterSection

  const pricingFilter = visibleFilters.includes('pricingFilter') &&
    (['austria', 'germany'].includes(countryName) || onEnterSectionFilters({}, {}).type !== 'event_location')

  const displayedFilters = {
    cityFilter: true,
    occasionFilter: visibleFilters.includes('occasionFilter'),
    locationTypeFilter: visibleFilters.includes('locationTypeFilter'),
    supplierTypeFilter: visibleFilters.includes('supplierTypeFilter'),
    guestFilter: visibleFilters.includes('guestFilter'),
    seatingTypeFilter: visibleFilters.includes('seatingTypeFilter'),
    pricingFilter,
    availabilityFilter: visibleFilters.includes('availabilityFilter'),
    sitingFilter: visibleFilters.includes('sitingFilter'),
    typeFilter: visibleFilters.includes('typeFilter'),
    roomSizeFilter: visibleFilters.includes('roomSizeFilter'),
    cateringOptionsFilter: visibleFilters.includes('cateringOptionsFilter'),
    technicalEquippingsFilter: visibleFilters.includes('technicalEquippingsFilter'),
    amenitiesFilter: visibleFilters.includes('amenitiesFilter'),
    hybridFilter: visibleFilters.includes('hybridFilter'),
    mapFilter: visibleFilters.includes('mapFilter'),
    hotelFilter: visibleFilters.includes('hotelFilter') || isHotel,
    switchEventsIdeas: visibleFilters.includes('switchEventsIdeas'),
    switchMeetingLocations: visibleFilters.includes('switchMeetingLocations'),
    switchConferenceCongressIdeas: visibleFilters.includes('switchConferenceCongressIdeas'),
    visibilityFilter: isConsultant && visibleFilters.includes('visibilityFilter'),
  }

  if (forceMobileFilters || portable) {
    const mobileFiltersOverlay = mountMobileFilters
      ? <MobileFiltersOverlay displayedFilters={displayedFilters} />
      : null

    return (
      <>
        {mobileFiltersOverlay}

        <MobileFilters displayedFilters={displayedFilters} />
      </>
    )
  }

  return <DesktopFilters displayedFilters={displayedFilters} />
}

ProviderFilter.propTypes = {
  forceMobileFilters: PropTypes.bool,
}

export default ProviderFilter
