import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'

import { toggleMobileMap as toggleMobileMapAction } from 'store/modules/ProvidersFilter'

import ToggleFiltersButton from './components/ToggleFiltersButton'

import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import '../shared.less'
import './index.less'

const MobileFilters = (props) => {
  const {
    displayedFilters,
  } = props
  const dispatch = useDispatch()
  const toggleMobileMap = bindActionCreators(toggleMobileMapAction, dispatch)

  return (
    <div className="provider-filter provider-filter--mobile">
      <div className="provider-filter__col">
        <ToggleFiltersButton />
      </div>

      {displayedFilters.mapFilter && (
        <div className="provider-filter__col">
          <Button
            type="default"
            onClick={toggleMobileMap}
            className={classNames(
              'ant-btn--brand-font',
              'provider-filter__button',
            )}
          >
            <Icon name="map-o" />
            <FormattedMessage id="common.map" />
          </Button>
        </div>
      )}
    </div>
  )
}

MobileFilters.propTypes = {
  displayedFilters: PropTypes.shape({
    mapFilter: PropTypes.bool,
  }).isRequired,
}

export default MobileFilters
