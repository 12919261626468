import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import classNames from 'classnames'

import Banner from './Banner'

import ProviderCard from 'components/ProviderCard'
import SmallProviderCard from 'components/ProviderCard/Small'
import FencingCard from 'components/FencingCard'

import { RootState } from 'store/reducer'
import { breakpoints } from 'helpers/responsive'

import { useMediaQuery } from './hooks'

import './index.less'

const Results: React.FC<ResultsProps> = (props) => {
  const {
    isMapVisible,
    projectId,
  } = props

  const {
    mobile,
    providerList,
    eventCategoryId,
    showBanner,
    showFencingCard,
  } = useSelector((state: RootState) => ({
    mobile: state.layout.mobile,
    providerList: state.providers.providerList,
    eventCategoryId: state.providers.eventCategoryId,
    showBanner: !!state.providers.seoData && state.providers.page === 1,
    showFencingCard: (
      state.headerData.experiments?.isFencingEnabled &&
      state.headerData.countryName === 'germany' &&
      state.providers.page === 1 &&
      (!state.currentUser.isSignedIn || state.currentUser.permissions?.upgrade_subscription)
    ),
  }), shallowEqual)

  const nonPremiumMembers = useMemo(
    () => providerList.filter((provider) => !provider.isPremium && provider.type === 'EventLocation'),
    [providerList],
  )
  const premiumMembers = useMemo(
    () => providerList.filter((provider) => provider.isPremium || provider.type !== 'EventLocation'),
    [providerList],
  )
  const [columnCount, setColumnCount] = useState(mobile ? 1 : 3)
  const placeholderCount = columnCount - (premiumMembers.length % columnCount)

  useMediaQuery(
    useMemo(() => {
      if (isMapVisible) { return }

      return {
        [`(max-width: ${breakpoints.sm}px)`]: () => { setColumnCount(1) },
        [`(min-width: ${breakpoints.sm + 1}px) and (max-width: ${breakpoints.lg}px)`]: () => { setColumnCount(2) },
        [`(min-width: ${breakpoints.lg + 1}px) and (max-width: ${breakpoints.xxl}px)`]: () => { setColumnCount(3) },
        [`(min-width: ${breakpoints.xxl + 1}px) and (max-width: 1920px)`]: () => { setColumnCount(4) },
        '(min-width: 1921px) and (max-width: 2400px)': () => { setColumnCount(5) },
        '(min-width: 2401px)': () => { setColumnCount(6) },
      }
    }, [isMapVisible]),
    [isMapVisible],
  )

  const columnCountWithFallback = isMapVisible ? 2 : columnCount
  const thirdRowColumnIndex = columnCountWithFallback * 2
  const secretPortfolioFencingCardIndex = Math.min(5, premiumMembers.length - 1)

  return (
    <div
      className={classNames(
        'filter-page__provider-list__results',
        `filter-page__provider-list__results--${columnCountWithFallback}-cols`,
      )}
    >
      {premiumMembers.reduce((itemsToRender: React.ReactNode[], provider, index) => {
        if (showBanner && index === thirdRowColumnIndex) {
          itemsToRender.push(<Banner key="filter-banner" />)
        }

        itemsToRender.push(
          <ProviderCard
            key={provider.id}
            provider={provider}
            eventCategoryId={eventCategoryId}
            queryParams={{ projectId }}
            isInList
          />,
        )

        if (showFencingCard && index === secretPortfolioFencingCardIndex) {
          itemsToRender.push(
            <FencingCard
              key="secret-portfolio-fencing"
              className="provider-card"
              feature="secret-portfolio-from-filterpage"
            />,
          )
        }

        return itemsToRender
      }, [])}

      {[...new Array(placeholderCount).keys()].map((index) => (
        <ProviderCard key={index} placeholder={true} />
      ))}

      {nonPremiumMembers.length > 0 && (
        <>
          {premiumMembers.length > 0 && (
            <div className="filter-page__provider-list__results__separator" />
          )}
          {nonPremiumMembers.map((provider) => (
            <SmallProviderCard
              key={provider.id}
              provider={provider}
              eventCategoryId={eventCategoryId}
              queryParams={{ projectId: projectId?.toString() }}
            />
          ))}
        </>
      )}
    </div>
  )
}

interface ResultsProps {
  isMapVisible: boolean
  projectId?: number
}

export default Results
