import React from 'react'
import PropTypes from 'prop-types'

import Error from 'components/Error'

import { nextjsGetPathAndQueryString, initialApiCall } from 'helpers/nextjsApiCall'
import { geoSearch, findAreaByMapboxId } from 'helpers/mapbox'

import { actionCreators as providersActions } from 'store/modules/Providers'
import { actionCreators as providersFilterActions } from 'store/modules/ProvidersFilter'

import HeadlessLayout from 'layout/HeadlessLayout'

import FilterPage from 'apps/FilterPage'

const FilterPageLoader = (props) => {
  const { statusCode } = props

  if (statusCode) {
    return <Error {...props} />
  }

  return <FilterPage {...props} />
}

FilterPageLoader.getInitialProps = async ({ reduxStore, req, res, asPath, query }) => {
  const state = reduxStore.getState().providers

  const {
    path,
    queryString,
    parsedQueryString,
  } = nextjsGetPathAndQueryString(asPath)

  const defaultPageProps = {
    hasSeoHeader: true,
    hideFilters: query.section === 'guide',
  }

  if (typeof window !== 'undefined' && state.pathname === path && state.queryString === queryString) {
    return defaultPageProps
  }

  reduxStore.dispatch(providersActions.setLoading({
    isActive: true,
    isInitial: true,
    hasPathnameChanged: path !== state.pathname,
  }))

  const initialResult = await initialApiCall(req, res, asPath, reduxStore)

  if (initialResult.success) {
    reduxStore.dispatch(providersActions.setInitialData({
      pathname: path,
      queryString,
      initialData: initialResult,
    }))

    reduxStore.dispatch(providersActions.setPageData({
      providerList: initialResult.providers,
    }))

    reduxStore.dispatch(providersFilterActions.setProvidersFilterInitialData({ initialData: initialResult }))

    reduxStore.dispatch(providersFilterActions.setProviderFilterFromQueryString({ params: parsedQueryString }))

    const selectedMapboxId = reduxStore.getState().providersFilter.filters.mapboxId
    const isFetchingMapboxAreaNeeded = selectedMapboxId && !findAreaByMapboxId(
      selectedMapboxId,
      reduxStore.getState(),
    )

    if (isFetchingMapboxAreaNeeded) {
      const mapboxAreaResult = await geoSearch(selectedMapboxId, { state: reduxStore.getState() })

      reduxStore.dispatch(providersFilterActions.setMapboxGeoLocationSearchResult(mapboxAreaResult.features))
    }

    reduxStore.dispatch(providersActions.setLoading(false))
  }

  return {
    ...initialResult,
    ...defaultPageProps,
  }
}

FilterPageLoader.Layout = HeadlessLayout

FilterPageLoader.lazyLoadSentry = true

FilterPageLoader.propTypes = {
  statusCode: PropTypes.number,
}

export default FilterPageLoader
