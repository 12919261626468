import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import Image from 'next/image'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import { useLocalStorage } from 'hooks/storage'

import './index.less'

const TOAST_ID = 'service_onboarding'

const ServiceToast = () => {
  const isDesktop = useSelector((state) => (state.layout.desktop))
  const [isSeen, setIsSeen] = useLocalStorage('SERVICE_ONBOARDING_SEEN')

  useEffect(() => {
    setTimeout(() => {
      if (!isSeen && isDesktop) {
        toast(
          serviceToastContent,
          {
            id: TOAST_ID,
            position: 'bottom-right',
            className: 'filter-page__service-toast',
            duration: Infinity,
            style: {
              width: '340px',
              padding: '21px 15px',
            },
          },
        )
      }
    }, 5000)
  }, [isSeen, isDesktop])

  const onClose = () => {
    toast.dismiss(TOAST_ID)
    setIsSeen(true)
  }

  const serviceToastContent = (
    <div className="filter-page__service-toast__content">
      <div className="filter-page__service-toast__content__header">
        <Image
          className="filter-page__service-toast__content__header__picture"
          src="/service_toast_profile_picture.jpg"
          width={50}
          height={50}
        />

        <span className="filter-page__service-toast__content__header__title">
          <FormattedMessage id="filterPage.serviceToast.heading" />
        </span>

        <span className="filter-page__service-toast__content__header__close" onClick={onClose}>
          <Icon name="close" />
        </span>
      </div>

      <div className="filter-page__service-toast__content__message">
        <FormattedMessage id="filterPage.serviceToast.message" />
      </div>
    </div>
  )

  return null
}

export default ServiceToast
