import React, { useRef, useState, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import { sortLocationTypeCards } from 'store/modules/ProvidersFilter'

import LocationTypeCard from './Card'
import Button from 'components/Button'
import Icon from 'components/Icon'

import './index.less'

const LocationTypeCards = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {
    locationTypeCards,
    hasOccasionFilter,
    isEventRoom,
    loading,
  } = useSelector((state) => ({
    locationTypeCards: state.providersFilter.locationTypeCards,
    hasOccasionFilter: !!state.providersFilter.filters.occasionFilter,
    isEventRoom: state.providers.providerType === 'event_room',
    loading: state.providers.loading.isActive,
  }), shallowEqual)
  const [scrollX, setScrollX] = useState(0)
  const [scrollEnd, setScrollEnd] = useState(false)
  const scrollable = useRef(null)

  const slide = (offset) => {
    scrollable.current.scrollLeft += offset

    setScrollX(scrollX + offset)
  }

  useEffect(() => {
    if (!hasOccasionFilter && !isEventRoom && !loading) {
      dispatch(sortLocationTypeCards(intl))
    }
  }, [loading])

  useEffect(() => {
    const setIsScrollEnd = () =>
      setScrollEnd(Math.floor(scrollable.current.scrollWidth - scrollX) <= scrollable.current.offsetWidth)

    setIsScrollEnd()
    window.addEventListener('resize', setIsScrollEnd)

    return () => { window.removeEventListener('resize', setIsScrollEnd) }
  }, [scrollX, scrollable.current])

  return (
    <div className="filter-page__location-type-cards">
      <div className="filter-page__location-type-cards__scroll-window">
        <div className="filter-page__location-type-cards__scroll-window__scrollable" ref={scrollable}>
          {locationTypeCards.map((card) => (
            <LocationTypeCard key={card.key} captionKey={card.key} {...card} />
          ))}
        </div>
      </div>

      {scrollX !== 0 && (
        <Button
          type="default"
          onClick={() => slide(-scrollable.current.offsetWidth)}
          className={classNames(
            'ant-btn--brand-font',
            'ant-btn--with-border-radius-small',
            'filter-page__location-type-cards__button-scroll',
            'filter-page__location-type-cards__button-scroll--left',
          )}
        >
          <Icon name="angle-left" />
        </Button>
      )}

      {!scrollEnd && (
        <Button
          type="default"
          onClick={() => slide(scrollable.current.offsetWidth)}
          className={classNames(
            'ant-btn--brand-font',
            'ant-btn--with-border-radius-small',
            'filter-page__location-type-cards__button-scroll',
            'filter-page__location-type-cards__button-scroll--right',
          )}
        >
          <Icon name="angle-right" />
        </Button>
      )}
    </div>
  )
}

export default LocationTypeCards
