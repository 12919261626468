import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import Image from 'next/image'

import Checkmark from '@assets/images/svg_icons/check-circle.svg'

import FormattedMessage from 'components/FormattedMessage'
import { setProvidersFilter } from 'store/modules/ProvidersFilter'
import { applyFilters } from 'helpers/filters'
import useBrowser from 'hooks/browser'

import './index.less'

const LocationTypeCard = (props) => {
  const {
    webpUrl,
    url,
    isActive,
    captionKey,
    id,
  } = props

  const router = useRouter()
  const currentFilters = useSelector((state) => state.providersFilter.filters.locationTypes)
  const dispatch = useDispatch()
  const { canWebp } = useBrowser()
  const imageUrl = canWebp() ? webpUrl : url
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '15px',
  })

  const handleSelectLocationType = () => {
    const newFilters = [...currentFilters]
    const currentIndex = newFilters.indexOf(id)

    if (newFilters.includes(id)) {
      newFilters.splice(currentIndex, 1)
    } else {
      newFilters.push(id)
    }

    dispatch(setProvidersFilter({ locationTypes: newFilters }, () => {
      applyFilters(router)
    }))
  }

  return (
    <div
      className="filter-page__location-type-cards__card"
      onClick={handleSelectLocationType}
      ref={ref}
    >
      {inView && (
        <Image
          className="filter-page__location-type-cards__card__image"
          alt={captionKey}
          src={imageUrl}
          layout="fill"
        />
      )}

      <span className="filter-page__location-type-cards__card__label">
        <FormattedMessage id={`location.type.cards.${captionKey}`} />
      </span>

      {isActive && (
        <Checkmark className="filter-page__location-type-cards__card__checkmark" />
      )}
    </div>
  )
}

LocationTypeCard.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  webpUrl: PropTypes.string,
  captionKey: PropTypes.string,
  isActive: PropTypes.bool,
}

export default LocationTypeCard
