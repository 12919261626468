import { useState } from 'react'

import storage from 'helpers/storage'
import { isServer } from 'helpers/utilities'
import { STORAGE_KEY, KEEP_STORED_FORM_KEYS } from 'components/ContactRequestFormComponents/constants'

export const usePreselectedFormData = (data) => {
  const contactRequestFormStorage = storage.get(STORAGE_KEY) || {}
  const [currentData, setCurrentData] = useState({ ...contactRequestFormStorage, ...data })

  const setPreselectedFormData = (data) => {
    if (isServer() || !data) { return }

    setCurrentData(data)
    storage.set(STORAGE_KEY, { ...contactRequestFormStorage, ...data })
  }

  return { preselectedFormData: currentData, setPreselectedFormData }
}

export const setPreselectedFormData = (data) => {
  // fallback to set data for preselecting from class components
  if (isServer() || !data) { return }

  storage.set(STORAGE_KEY, { ...(storage.get(STORAGE_KEY) || {}), ...data })
}

export const clearPreselectedFormData = () => {
  // fallback to clear data for form preselecting from class components
  if (isServer()) { return }

  const dataToBeCleared = KEEP_STORED_FORM_KEYS.filter(key => key !== 'projectId')
    .reduce((formData, current) => ({ ...formData, [current]: undefined }), {})

  storage.set(STORAGE_KEY, { ...(storage.get(STORAGE_KEY) || {}), ...dataToBeCleared })
}
