import React from 'react'
import { useSelector } from 'react-redux'

import FormattedMessage from 'components/FormattedMessage'

import { RootState } from 'store/reducer'

import './index.less'

const ResultsCount: React.FC<ResultsCountProps> = (props) => {
  const stateTotal = useSelector((state: RootState) => state.providers.total)
  const total = props.total || stateTotal

  return (
    <div className="filter-page__provider-list__results-count">
      {total} <FormattedMessage id={total > 1 ? 'providersFilter.results' : 'providersFilter.result'} />
    </div>
  )
}

interface ResultsCountProps {
  total?: number
}

export default ResultsCount
